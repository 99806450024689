import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const isString = (value) => {
  return typeof value === 'string' || value instanceof String
}
class MarriageBags extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="columns is-multiline">
        <div className="flex flex-wrap pb-6 bg-gray-200">
          {posts &&
            posts.map(({ node: post }) => {
              console.log(post)
              return (
                <React.Fragment key={post.id}>
                  <div className="flex-shrink w-1/2 mt-4 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6">
                    <div className="flex flex-col items-center p-2 ">
                      <div className="bg-white rounded-lg">
                        <Link to={post.fields.slug}>
                          <img
                            srcSet={
                              isString(post.frontmatter.productImage)
                                ? post.frontmatter.productImage
                                : post.frontmatter.productImage.childImageSharp
                                    .fluid.srcSet
                            }
                            className="h-auto rounded-md shadow-2xl sm:h-56 lg:h-64"
                            alt=""
                          />
                        </Link>
                      </div>

                      <div className="-m-4 bg-white rounded-lg shadow-lg w-54 sm:w-64">
                        <div className="px-5 py-2">
                          <div className="flex items-center justify-between">
                            <div className="font-light text-gray-600 text-m">
                              <p>SKU: {post.frontmatter.sku}</p>
                              {/* <p>Size : {post.frontmatter.size}</p> */}
                              {/* {post.frontmatter.size && (
                                <div class="flex ">
                                  <div className="flex-initial pr-1 mt-1">
                                    {' '}
                                    Size:{' '}
                                  </div>
                                  {post.frontmatter.size.map((lineItem) => (
                                    <div className="flex-initial px-3 py-1 mr-2 bg-red-900 rounded">
                                      {' '}
                                      {lineItem}
                                    </div>
                                  ))}
                                </div>
                              )} */}
                            </div>
                            {post.frontmatter.isPriceVisible && (
                              <div className="pl-4 font-bold text-red-600 text-s sm:text-2xl">
                                ₹ {post.frontmatter.unitPrice}
                              </div>
                            )}

                            {!post.frontmatter.isPriceVisible && (
                              <a href={'tel:+919944180533'}>
                                <div className="p-2 font-bold rounded text-brand text-s sm:text-2xl hover:bg-brand hover:text-white">
                                  <svg
                                    className="w-6 h-6"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M16 3h5m0 0v5m0-5l-6 6M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
                                    ></path>
                                  </svg>
                                </div>
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
            })}
        </div>
      </div>
    )
  }
}

MarriageBags.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query MarriageBagsQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "marriagebags-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                templateKey
                sku
                productImage {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                isPriceVisible
                unitPrice
                size
                inStock
                dimensions
                promotion {
                  meta {
                    shortdescription
                    keywords {
                      keyword
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <MarriageBags data={data} count={count} />}
  />
)
