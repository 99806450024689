import React from 'react'

import Layout from '../../components/Layout'
import MarriageBags from '../../components/MarriageBags'

export default class MarriageBagsIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        {/* <div
          className="full-width-image-container margin-top-0"
          style={{
            backgroundImage: `url('/img/blog-index.jpg')`,
          }}
        >
          <h1
            className="has-text-weight-bold is-size-1"
            style={{
              boxShadow: '0.5rem 0 0 #f40, -0.5rem 0 0 #f40',
              backgroundColor: '#f40',
              color: 'white',
              padding: '1rem',
            }}
          >
            Latest Stories
          </h1>
        </div> */}

        <section>
          <nav className="flex flex-wrap items-center justify-between p-6">
            <div className="flex items-center w-48 mr-6 border-b-4 border-brand">
              <span className="text-xl font-semibold tracking-tight ">
                Marriage Bags
              </span>
            </div>
          </nav>
          <MarriageBags />
        </section>
      </Layout>
    )
  }
}
